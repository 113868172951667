import React from "react";

const ErrorMessage = (props) => {
	return (
		<div className="w-full max-w-sm border rounded-md h-12 text-sm flex items-center">
			<div
				className={"bg-red h-full w-2 rounded-l-md justify-self-center"}
			></div>
			<p className={"ml-3 text-gray"}>
				<span className={"text-red uppercase font-bold"}>VIGA:</span>{" "}
				{props.error}
			</p>
		</div>
	);
};

export default ErrorMessage;